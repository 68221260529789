
import { Component, Vue, Prop } from 'vue-property-decorator';
import HandleImage from '../../modules/shared/services/handle-image.service';

@Component
export default class UploadComponent extends Vue {
  public $refs: any;

  @Prop({
    type: String,
    default: () => 'Selecione seus arquivos',
  })
  private readonly label!: string;

  @Prop({
    type: String,
    default: () => 'Selecione seus arquivos',
  })
  private readonly placeholder!: string;

  @Prop({
    type: String,
    default: () => '',
  })
  private readonly accept!: string;

  @Prop({
    type: Boolean,
    default: () => false,
  })
  private readonly multiple!: boolean;

  @Prop({
    type: Boolean,
    default: () => true,
  })
  private readonly showSize!: boolean;

  @Prop({
    type: Boolean,
    default: () => true,
  })
  private readonly counter!: boolean;

  @Prop({
    default: () => ({}),
  })
  private readonly value!: any[];

  @Prop({
    type: Array,
    default: () => [],
  })
  private readonly rules!: any[];

  @Prop({
    type: String,
    default: () => '',
  })
  private readonly labelChip!: string;

  private currentImage!: File[];

  private error: string = '';

  private removeFile() {
    this.$emit('remove-file');
  }

  private async setFile(file: File[]) {
    if (!this.$refs.form.validate()) return;
    if (file.length > 0) {
      this.$emit('input', file);
      return;
    }
    if (file) {
      this.$emit('input', [file]);
    }
    this.removeFile();
  }

  private validateFile(file: any) {
    const errorMessage = 'Não é possivel enviar imagens maiores do que 5mb';
    if (!file) return true;
    if (typeof file === 'string') return true;
    if (file instanceof Array && !file.length) return true;

    if (file instanceof Array && file.length) {
      return file.every((it: any) => it.size <= 5000000) === true ? true : errorMessage;
    }
    return file.size <= 5000000 ? true : errorMessage;
  }

  public validate() {
    return this.$refs.form.validate();
  }
}
