
import { Component, Vue } from 'vue-property-decorator';
import rulesService from '@/services/rules.service';
import DeleteDialog from '@/components/dialogs/delete-dialog.component.vue';
import AlertError from '@/components/alert-error/alert-error.component.vue';
import DatePicker from '@/components/date-picker/date-picker.component.vue';
import AddressComponent from '@/components/address/address.component.vue';
import handleImageService from '@/modules/shared/services/handle-image.service';

import { Company } from '../../entities/company.entity';
import companiesService from '../../services/companies.service';
import { AddressViaCepInterface } from '../../../../../../components/address/interfaces/address-via-cep.interface';
import { Address } from '../../../../../../components/address/entities/address.entity';
import UploadComponent from '../../../../../../components/upload/upload.component.vue';
import RenderApp from '../../../../../../services/render.service';
import { CompanyAttachmentEnum } from './enums/company-attachment-enum.enum';

@Component({
  components: {
    AlertError,
    DatePicker,
    AddressComponent,
    UploadComponent,
    DeleteDialog,
  },
})
export default class CompanyCreateComponent extends Vue {
  public $refs: any;

  private company: Company = new Company();

  private showDialogDelete: boolean = false;

  private errors: any[] = [];

  private logoFile: any = '';

  private bgFile: any = '';

  private logoFileToShow: any = '';

  private bgFileToShow: any = '';

  private isExempt: boolean = false;

  private status: any[] = [
    {
      id: 0,
      name: 'Inativo',
    },
    {
      id: 1,
      name: 'Ativo',
    },
  ];

  private rules: any = {
    required: rulesService.required,
    onlyNumber: rulesService.onlyNumber,
  };

  private get hasError(): boolean {
    return Object.keys(this.errors).length > 0;
  }

  private get labelFormType() {
    return this.isEdit ? 'Editar' : 'Cadastrar';
  }

  private get isEdit() {
    const { id } = this.$route.params;
    return !!id;
  }

  private get showAddressComponent() {
    if (!this.isEdit) {
      return true;
    }
    return !!this.company.address.cep;
  }

  private setShowDialogDelete(company: Company) {
    this.showDialogDelete = true;
  }

  private closeDialogDelete() {
    this.showDialogDelete = false;
  }

  private deleteItem() {
    this.company
      .delete()
      .then(() => {
        this.$snackbar.open({
          text: 'Empresa excluida com sucesso',
          color: 'success',
          buttonColor: '#fff',
        });
        this.goToList();
      })
      .catch((err: any) => {
        this.$snackbar.open({
          text: err.response.data.message,
          color: 'danger',
          buttonColor: '#fff',
        });
        throw new Error(err);
      })
      .finally(() => {
        this.closeDialogDelete();
      });
  }

  private setIsExempt(data: boolean) {
    if (data) {
      this.company.state_registration = 'ISENTO';
      return;
    }
    this.company.state_registration = '';
  }

  private removeLogo() {
    this.company
      .removeLogo()
      .then(() => {
        this.logoFileToShow = '';
        this.logoFile = '';
        this.$snackbar.open({
          text: 'Logo removida com sucesso',
          color: 'success',
          buttonColor: '#fff',
        });
      })
      .catch(() => {
        this.$snackbar.open({
          text: 'Erro ao remover a logo',
          color: 'danger',
          buttonColor: '#fff',
        });
      });
  }

  private removeBackground() {
    this.company
      .removeBackground()
      .then(() => {
        this.bgFileToShow = '';
        this.bgFile = '';
        this.$snackbar.open({
          text: 'Background removido com sucesso',
          color: 'success',
          buttonColor: '#fff',
        });
      })
      .catch(() => {
        this.$snackbar.open({
          text: 'Erro ao remover a Background',
          color: 'danger',
          buttonColor: '#fff',
        });
      });
  }

  private setLogo(logoFile: any) {
    const formData = new FormData();
    const logo = logoFile[0];
    formData.append('file', logo);
    this.company
      .setLogo(formData)
      .then((data: any) => {
        this.logoFile = logoFile;
        this.logoFileToShow = data.path;
      })
      .catch(() => {
        this.$snackbar.open({
          text: 'Erro ao salvar a logo',
          color: 'danger',
          buttonColor: '#fff',
        });
      });
  }

  private async setBackground(backgroundFile: any) {
    const formData = new FormData();
    const bgFile = backgroundFile[0];
    formData.append('file', bgFile);
    this.company
      .setBackground(formData)
      .then((data: any) => {
        this.bgFile = backgroundFile;
        this.bgFileToShow = data.path;
      })
      .catch(() => {
        this.$snackbar.open({
          text: 'Erro ao salvar o background',
          color: 'danger',
          buttonColor: '#fff',
        });
      });
  }

  private refreshUser() {
    this.$router
      .push({
        name: 'home',
      })
      .then(() => {
        window.location.reload();
      });
  }

  private setLogoAndBackgroundToShow() {
    this.logoFile = this.company.getAttachmentByType(CompanyAttachmentEnum.GENERAL);
    this.logoFileToShow = this.logoFile;
    this.bgFile = this.company.getAttachmentByType(CompanyAttachmentEnum.BACKGROUND);
    this.bgFileToShow = this.bgFile;
  }

  private async getUnitTypeById() {
    this.$loader.open();
    const { id } = this.$route.params;
    const company = await companiesService.getCompanyById(id);
    this.company = new Company(company);
    this.isExempt = this.company.state_registration === 'ISENTO';
    this.setLogoAndBackgroundToShow();
    this.$loader.close();
  }

  private async submit() {
    if (!this.$refs.form.validate()) return;
    if (this.isEdit) {
      this.edit();
      return;
    }
    this.save();
  }

  private async save() {
    this.$loader.open();
    this.company
      .save()
      .then(() => {
        this.errors = [];
        this.$snackbar.open({
          text: 'Empresa cadastrada com sucesso',
          color: 'success',
          buttonColor: '#fff',
        });

        this.refreshUser();
      })
      .catch((err: any) => {
        this.$snackbar.open({
          text: 'Erro ao cadastrar empresa',
          color: 'danger',
          buttonColor: '#fff',
        });
        this.errors = err.response.data.errors;
      })
      .finally(() => {
        this.$loader.close();
      });
  }

  private async edit() {
    this.$loader.open();
    this.company
      .update()
      .then(() => {
        this.errors = [];
        this.$snackbar.open({
          text: 'Empresa alterada com sucesso',
          color: 'success',
          buttonColor: '#fff',
        });
        this.refreshUser();
      })
      .catch((err: any) => {
        this.errors = err.response && err.response.data.errors;
      })
      .finally(() => {
        this.$loader.close();
      });
  }

  private async setAddressByCep(address: AddressViaCepInterface) {
    const fullAddress = { ...address, number: this.company.address.number };
    this.company.address = new Address(fullAddress);
  }

  private async goToList() {
    this.$router.push({
      name: 'company-list',
    });
  }

  private created() {
    if (this.isEdit) {
      this.getUnitTypeById();
    }
  }
}
